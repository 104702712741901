<template>
  <!--临时数据导出-->
  <div style="padding: 20px;">
    <el-card shadow="never" v-loading="loading">
      <el-form
        :model="form"
        label-width="100px"
        class="ele-form-search"
        @submit.native.prevent>
        <div style="margin-top: 20px;margin-bottom: 15px;text-align: center;font-size: 16px;">
          调度客服考核数据表导出
        </div>
        <el-row :gutter="15">
          <el-col :span="7">
            <el-form-item label="时间:">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="form.time"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="选择服务商:">
              <el-select
                filterable
                clearable
                v-model="form.firmId"
                placeholder="请选择"
                class="ele-fluid">
                <el-option
                  v-for="(item,index) in all_selectList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <div class="ele-form-actions">
              <el-button
                type="primary"
                class="ele-btn-icon"
                @click="serviecexport">导出
              </el-button>
            </div>
          </el-col>
        </el-row>

        <div style="margin-top: 20px;margin-bottom: 15px;text-align: center;font-size: 16px;">
          市场人员签约服务商数量考核数据导出
        </div>
        <el-row :gutter="15">
          <el-col :span="7">
            <el-form-item label="时间:">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="form1.time"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="选择服务商:">
              <el-select
                filterable
                clearable
                v-model="form1.firmId"
                placeholder="请选择"
                class="ele-fluid">
                <el-option
                  v-for="(item,index) in all_selectList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <div class="ele-form-actions">
              <el-button
                type="primary"
                class="ele-btn-icon"
                @click="serviecexport1">导出
              </el-button>
            </div>
          </el-col>
        </el-row>

        <div style="margin-top: 20px;margin-bottom: 15px;text-align: center;font-size: 16px;">
          市场人员签约服务商质量考核数据导出
        </div>
        <el-row :gutter="15">
          <el-col :span="7">
            <el-form-item label="时间:">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="form2.time"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="选择服务商:">
              <el-select
                filterable
                clearable
                v-model="form2.firmId"
                placeholder="请选择"
                class="ele-fluid">
                <el-option
                  v-for="(item,index) in all_selectList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <div class="ele-form-actions">
              <el-button
                type="primary"
                class="ele-btn-icon"
                @click="serviecexport2">导出
              </el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
  //引入的接口
  import {all_select, assess_data, number_assess, quantity_assess} from "@/api/yunli";
  export default {
    data() {
      return {
        // 导出调度客服考核数据表条件
        form:{
          time:[],
          firmId:'',
        },
        // 导出调度客服考核数据表条件
        form1:{
          time:[],
          firmId:'',
        },
        // 导出调度客服考核数据表条件
        form2:{
          time:[],
          firmId:'',
        },

        // 加载
        loading:false,

        // 所有服务商
        all_selectList:[],
      }
    },

    mounted() {
      // 调用获取所有服务商
      this.getall_select();
    },

    methods:{
      // 获取全部服务商
      getall_select(){
        all_select().then(res => {
          this.all_selectList = res.data;
        })
      },

      // 导出调度客服考核数据表
      serviecexport(){
        if(this.form.firmId == '' || this.form.time.length == 0){
          this.$message.error('时间和服务商为必填项哦！')
        }else {
          this.loading = true;
          assess_data({
            firmId: this.form.firmId,
            time: this.form.time
          }).then(res => {
            if(res.code == 200){
              this.$message({
                message: "正在导出中...<br>导出完成后请到<span style='color: #00a7ff'>运力里的导出日志下载</span>",
                dangerouslyUseHTMLString: true,
                type: 'success',
                customClass: 'zZindexs',
                duration: 5000
              });
              this.form.firmId = '';
              this.form.time = [];
              this.loading = false;
            }else {
              this.$message.error(res.msg);
              this.loading = false;
            }
          })
        }
      },


      // 导出市场人员签约服务商数量考核数据
      serviecexport1(){
        if(this.form1.firmId == '' || this.form1.time.length == 0){
          this.$message.error('时间和服务商为必填项哦！')
        }else {
          this.loading = true;
          number_assess({
            firmId: this.form1.firmId,
            time: this.form1.time
          }).then(res => {
            if(res.code == 200){
              this.$message({
                message: "正在导出中...<br>导出完成后请到<span style='color: #00a7ff'>运力里的导出日志下载</span>",
                dangerouslyUseHTMLString: true,
                type: 'success',
                customClass: 'zZindexs',
                duration: 5000
              });
              this.form1.firmId = '';
              this.form1.time = [];
              this.loading = false;
            }else {
              this.$message.error(res.msg);
              this.loading = false;
            }
          })
        }
      },

      // 导出市场人员签约服务商质量考核数据
      serviecexport2(){
        if(this.form2.firmId == '' || this.form2.time.length == 0){
          this.$message.error('时间和服务商为必填项哦！')
        }else {
          this.loading = true;
          quantity_assess({
            firmId: this.form2.firmId,
            time: this.form2.time
          }).then(res => {
            if(res.code == 200){
              this.$message({
                message: "正在导出中...<br>导出完成后请到<span style='color: #00a7ff'>运力里的导出日志下载</span>",
                dangerouslyUseHTMLString: true,
                type: 'success',
                customClass: 'zZindexs',
                duration: 5000
              });
              this.form2.firmId = '';
              this.form2.time = [];
              this.loading = false;
            }else {
              this.$message.error(res.msg);
              this.loading = false;
            }
          })
        }
      },

    },

  }
</script>

<style lang="less" scoped>

</style>
